export const apiEnvironment = {
  // url: 'http://localhost:3003', socket: 'http://localhost:4003',
  // url: 'https://novaapi.qa.zeebra.tech', socket: 'https://novaapi.qa.zeebra.tech',
  url: 'https://apinova.zeebra.tech', socket: 'https://apinova.zeebra.tech',
  // url: 'http://192.168.144.16:3063',  socket: 'http://192.168.144.16:4063',
  hookUrl: 'https://apivisor.partners.com.ec/api/v1/transaction/push',
  // tslint:disable-next-line:max-line-length
  hookToken: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NCwiY29kZSI6Im52LTAwMSIsIm5hbWUiOiJOT1ZBX0FURU5fQ0xJIiwia2V5Ijoibm92YUluIiwiY2FtcGFpZ24iOiJOT1ZBX0FURU5fQ0xJIiwiY3JlYXRlZEF0IjoiMjAyMC0wNy0xNlQxNjozNToyNy42MjBaIiwidXBkYXRlZEF0IjoiMjAyMS0xMi0wMVQxNDo1Mzo0MC4wMDBaIiwiZGVsZXRlZEF0IjpudWxsLCJjb21wYW55Ijo0LCJpYXQiOjE2MzgzNzA0MjB9.fwWQW1LM9a0Fxe_yFizAPMwAQlZ53XH1C9tuQt_jwOg',
};
export const campaignConfig = {
  types: [
    { label: 'Cobranzas', value: 'COLLECTION' },
    { label: 'Adjudicados', value: 'AWARD' }
  ],
  call_status: [
    { label: 'CONTACTADO', value: 'CONTACTED' },
    { label: 'NO CONTACTADO', value: 'NOT_CONTACTED' },
  ],
  management_status: [
    { label: 'PAGADO', value: 'PAID' },
    { label: 'PROBLEMA', value: 'PROBLEM' },
    { label: 'NO LOCALIZADO', value: 'NOT_LOCALIZED' },
    { label: 'MENSAJE', value: 'MESSAGE' },
    { label: 'COMPROMISO', value: 'COMMITMENT' },
  ],
  paid_ranges: [
    { label: '1 a 4', value: '1_4' },
    { label: '5 a 12', value: '5_12' },
    { label: 'más de 12', value: '13_100' }
  ],
  pending_fees_ranges: [
    { label: '30 DÍAS', value: 0 },
    { label: '60 DÍAS', value: 1 },
    { label: '90 DÍAS', value: 2 }
  ],
  agentCode: [
    { label: 'Grupos', value: 'ASG001', campaignType: 'COLLECTION' },
    { label: 'Renting', value: 'ASR001', campaignType: 'AWARD' }
  ]
};

export const managementEngine = {
  status: {
    enabled: { label: 'HABILITADO', value: 'ESTAGT0001' },
    onCall: { label: 'HABILITADO', value: 'ESTAGT0006' },
    inManagement: { label: 'HABILITADO', value: 'ESTAGT0007' }
  }
};
